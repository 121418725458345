import { Routes, Route } from 'react-router-dom';
import Home from '../pages/Home';
import Projetos from '../pages/Projetos';
import Error from '../pages/Error';

function RoutesApp() {
    return (
        <Routes>
            <Route path="/" element={<Home />} /> {/* home page */}
            <Route path="/projetos" element={<Projetos />} /> {/* projects page */}
            {/* <Route path="/home" element={<Home />} /> */}
            {/* <Route path="/editarUsuarios" element={<EditUsers />} /> */}
            <Route path="*" element={<Error />} />
        </Routes>
    );
}

export default RoutesApp;