import { Link } from 'react-router-dom'
import './projetos.css'
import '../../geral.css'


export default function Projetos (){
    return(
      <div className="container-Projetos">
            
            <h1 className="text-center pt-3">Meus projetos</h1>

            <div class="container px-4 mt-3">
              <div className="container-btn-back d-flex justify-content-end mb-3">
            <Link to="/" className="btn-back mb-4">Voltar à home</Link>
            </div>
        <div class="row row-cols-1 row-cols-lg-3 g-4">

<div className="col mt-0">
<div class="card">
  <div class="card-body">
    <h5 class="card-title">Sistema de chamados</h5>
    <p class="card-text card-description text-capitalize"><strong className="pe-1 text-capitalize">tecnologias:</strong>react, firebase, CSS</p>
    <div className="d-flex flex-column">
    <a href="https://calling-system-pink.vercel.app/" target="_blank" class="btn-card mb-2"><i class="fa-solid fa-earth-americas pe-2"></i>Deploy</a>
    <a href="https://github.com/dinizdv/React/tree/main/projects/calling_system" target="_blank" class="btn-card"><i class="fa-brands fa-github pe-2"></i>Repositório</a>
    </div>
  </div>
</div>
</div>

<div className="col mt-0">
<div class="card">
  <div class="card-body">
    <h5 class="card-title">landing page - cursos</h5>
    <p class="card-text card-description text-capitalize"><strong className="pe-1 text-capitalize">tecnologias:</strong>react, CSS</p>
    <div className="d-flex flex-column">
    <a href="https://react-landing-page-courses.vercel.app/" target="_blank" class="btn-card mb-2"><i class="fa-solid fa-earth-americas pe-2"></i>Deploy</a>
    <a href="https://github.com/dinizdv/React/tree/main/projects/landing_pages/courses" target="_blank" class="btn-card"><i class="fa-brands fa-github pe-2"></i>Repositório</a>
    </div>
  </div>
</div>
</div>

<div className="col mt-0">
<div class="card">
  <div class="card-body">
    <h5 class="card-title">landing page - NFT</h5>
    <p class="card-text card-description text-capitalize"><strong className="pe-1 text-capitalize">tecnologias:</strong>react, CSS</p>
    <div className="d-flex flex-column">
    <a href="https://react-landing-page-tech.vercel.app/" target="_blank" class="btn-card mb-2"><i class="fa-solid fa-earth-americas pe-2"></i>Deploy</a>
    <a href="https://github.com/dinizdv/React/tree/main/projects/landing_pages/tech" target="_blank" class="btn-card"><i class="fa-brands fa-github pe-2"></i>Repositório</a>
    </div>
  </div>
</div>
</div>

        <div className="col mt-0">
<div class="card">
  <div class="card-body">
    <h5 class="card-title">lista de tarefas</h5>
    <p class="card-text card-description text-capitalize"><strong className="pe-1 text-capitalize">tecnologias:</strong>react, firebase</p>
    <div className="d-flex flex-column">
    <a href="https://todo-list-with-react-and-firebase.vercel.app/" target="_blank" class="btn-card mb-2"><i class="fa-solid fa-earth-americas pe-2"></i>Deploy</a>
    <a href="https://github.com/dinizdv/React/tree/main/projects/login_database" target="_blank" class="btn-card"><i class="fa-brands fa-github pe-2"></i>Repositório</a>
    </div>
  </div>
</div>
</div>

<div className="col mt-0">
<div class="card">
  <div class="card-body">
    <h5 class="card-title">Landing page SENAI</h5>
    <p class="card-text card-description text-capitalize"><strong className="pe-1 text-capitalize">tecnologias:</strong>bootstrap, CSS</p>
    <div className="d-flex flex-column">
    <a href="https://bootstrap-senai.vercel.app/" target="_blank" class="btn-card mb-2"><i class="fa-solid fa-earth-americas pe-2"></i>Deploy</a>
    <a href="https://github.com/dinizdv/Bootstrap/tree/main/Bootstrap%20-%20Projects/WebPage_Senai" target="_blank" class="btn-card"><i class="fa-brands fa-github pe-2"></i>Repositório</a>
    </div>
  </div>
</div>
</div>

<div className="col mt-0">
<div class="card">
  <div class="card-body">
    <h5 class="card-title">filmes - API the movie DB</h5>
    <p class="card-text card-description text-capitalize"><strong className="pe-1 text-capitalize">tecnologias:</strong>react, CSS</p>
    <div className="d-flex flex-column">
    <a href="https://setfilmsproject.vercel.app/" target="_blank" class="btn-card mb-2"><i class="fa-solid fa-earth-americas pe-2"></i>Deploy</a>
    <a href="https://github.com/dinizdv/React/tree/main/projects/films" target="_blank" class="btn-card"><i class="fa-brands fa-github pe-2"></i>Repositório</a>
    </div>
  </div>
</div>
</div>



<div className="col mt-0">
<div class="card">
  <div class="card-body">
    <h5 class="card-title">sorteio entre números</h5>
    <p class="card-text card-description text-capitalize"><strong className="pe-1 text-capitalize">tecnologias:</strong>hTML, CSS, javaScript</p>
    <div className="d-flex flex-column">
    <a href="https://js-draw.vercel.app/" target="_blank" class="btn-card mb-2"><i class="fa-solid fa-earth-americas pe-2"></i>Deploy</a>
    <a href="https://github.com/dinizdv/JavaScript/tree/main/projects/draw" target="_blank" class="btn-card"><i class="fa-brands fa-github pe-2"></i>Repositório</a>
    </div>
  </div>
</div>
</div>

    </div>
</div>


        </div>
    )
}