import { Link } from "react-router-dom"
import './error.css'

export default function Error(){
    return(
        <div className="container-fluid">
        <div class="container-error container d-flex justify-content-center align-items-center flex-column">
            <h1 id="title-404" class="mt-0">404</h1>
            <h3 id="ops" class="mt-3">Ops, página não encontrada!</h3>
            <p id="p-backToHome">Algum problema ocorreu e não conseguimos acessar esta página! Retorne ao site para acessar os recursos.</p>
            <Link to={"/"} className="text-center mt-3" id="link-backToHome">Voltar ao site<i className="fa-solid fa-globe ps-2 fs-5" id="backToHome"></i></Link>
        
        </div>
        </div>
    )
}