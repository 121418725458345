import { Link } from "react-router-dom";
import "./home.css";
import "../../geral.css";
import diniz from "../../assets/diniz-website.png";
import Curriculo from '../../assets/Curriculo.docx'

export default function Home() {
  return (
    <div>
      <div class="wrapper">
        <aside id="sidebar" class="js-sidebar">
          {/* <!-- Content For Sidebar --> */}
          <div class="h-100">
            <div class="sidebar-logo mt-2">
              <a href="#" id="logo">
                dinizdev.com.br
              </a>
            </div>
            <ul class="sidebar-nav">
              <li class="sidebar-header">Mídias sociais</li>

              <li class="sidebar-item">
                <a href="https://github.com/dinizdv" target="_blank" class="sidebar-link">
                  <i class="fa-brands fa-github pe-2"></i>
                  GitHub
                </a>
              </li>

              <li class="sidebar-item">
                <a href="https://www.linkedin.com/in/bruno-diniz-oliveira-426a67286/" target="_blank" class="sidebar-link">
                  <i class="fa-brands fa-linkedin pe-2"></i>
                  LinkedIn
                </a>
              </li>

              <li class="sidebar-item">
              <a href="mailto:brunodinizoliveira3@gmail.com?body=Ol%C3%A1%2C%20Bruno%20Diniz!" target="_blank" class="sidebar-link">
                  <i class="fa-solid fa-envelope pe-2"></i>
                  E-mail
                </a>
              </li>

              <hr className="mb-0" />
              <li class="sidebar-header">Habilidades</li>

              <li class="sidebar-item">
                <a
                  href="#"
                  class="sidebar-link collapsed"
                  data-bs-target="#pages"
                  data-bs-toggle="collapse"
                  aria-expanded="false"
                >
                  <i class="fa-solid fa-list pe-2"></i>
                  Stacks
                </a>
                <ul
                  id="pages"
                  class="sidebar-dropdown list-unstyled collapse"
                  data-bs-parent="#sidebar"
                >
                  <li class="sidebar-stacks sidebar-item">
                    <a href="#" class="sidebar-link ms-3">
                      <i class="fa-brands fa-html5 pe-2"></i>HTML
                    </a>
                  </li>

                  <li class="sidebar-stacks sidebar-item">
                    <a href="#" class="sidebar-link ms-3">
                      <i class="fa-brands fa-css3-alt pe-2"></i>CSS
                    </a>
                  </li>

                  <li class="sidebar-stacks sidebar-item">
                    <a href="#" class="sidebar-link ms-3">
                      <i class="fa-brands fa-react pe-2"></i>React
                    </a>
                  </li>
                  <li class="sidebar-stacks sidebar-item">
                    <a href="#" class="sidebar-link ms-3">
                      <i class="fa-brands fa-bootstrap pe-2"></i>Bootstrap
                    </a>
                  </li>
                  <li class="sidebar-stacks sidebar-item">
                    <a href="#" class="sidebar-link ms-3">
                      <i class="fa-brands fa-figma pe-2"></i>Figma
                    </a>
                  </li>
                </ul>

                <li class="sidebar-item">
                  <a href={Curriculo} class="sidebar-link">
                  <i class="fa-solid fa-file-arrow-down pe-2"></i>
                    Currículo
                  </a>
                </li>
              </li>
            </ul>
          </div>
        </aside>
        <div class="main">
          <nav class="navbar navbar-expand">
            {/* button -> responsive sidebar */}
            <button
              className="btn-sidebar btn py-1 px-2 mt-3"
              id="sidebar-toggle"
              type="button"
              onClick={() =>
                document.getElementById("sidebar").classList.toggle("collapsed")
              }
            >
              <span className="navbar-toggler-icon"></span>
            </button>
          </nav>
          <main class="content px-3 py-2">
            <div class="container-fluid">
              {/* <div class="mb-3">
                <h4 class="text-light">Seja bem-vindo(a) ao meu site!</h4>
              </div> */}
              <div class="row mt-4">
                <div class="col-12 col-md-6 col-xl d-flex">
                  <Link
                    to="projetos" // to projects page
                    class="card flex-fill border-0 illustration card-info bg-primary text-light"
                  >
                    <div class="card-body p-0 d-flex flex-fill">
                      <div class="row g-0 w-100">
                        <div class="col-10">
                          <div class="p-3 m-1">
                            <h3>
                              <i class="fa-solid fa-code pe-2"></i>Meus projetos
                            </h3>
                            <p class="mb-0">
                              Projetos hospedados + links dos repositórios no
                              GitHub
                            </p>
                          </div>
                        </div>
                        <div class="col-6 align-self-end text-end"></div>
                      </div>
                    </div>
                  </Link>
                </div>

                <div class="col-12 col-md-6 col-xl d-flex">
                  <a href="https://www.linkedin.com/in/bruno-diniz-oliveira-426a67286/" target="_blank"
                    class="card flex-fill border-0 illustration card-info bg-primary text-light"
                  >
                    <div class="card-body p-0 d-flex flex-fill">
                      <div class="row g-0 w-100">
                        <div class="col-10">
                          <div class="p-3 m-1">
                            <h3>
                                <i class="fa-brands fa-linkedin pe-2"></i>LinkedIn
                            </h3>
                            <p class="mb-0">
                              Dicas sobre front-end/back-end toda semana!
                            </p>
                          </div>
                        </div>
                        <div class="col-6 align-self-end text-end"></div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>

              <div className="apresentation d-flex flex-column-reverse flex-lg-row justify-content-between rounded">
                <div className="col-12 col-md-5"><img src={diniz} id="diniz-background" /></div>
                <section className="section-apresentation d-flex flex-column px-5 col">
                  <h1 className="hello text-light pt-3 text-center text-lg-start">
                    Bruno Diniz
                  </h1>

                  <h3 className="ps-md-1 dev-front-end text-center text-lg-start">
                    Desenvolvedor front-end
                  </h3>
                  <div className="apresentation-linkedin d-flex align-items-center ps-md-1 ms-auto me-auto ms-lg-0 me-lg-0">
                  <i class="fa-brands fa-linkedin pe-2"></i><label>1.000.000+ visualizações</label></div>
                    <div className="apresentation-linkedin d-flex align-items-center ps-md-1 ms-auto me-auto ms-lg-0 me-lg-0">
                    <i class="fa-brands fa-linkedin pe-2"></i><label>13.000+ seguidores</label></div>
                </section>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
}
