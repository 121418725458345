import { BrowserRouter, Route } from 'react-router-dom'
import RoutesApp from '../src/routes/routes'
import './geral.css';

function App() {
  return (
    <BrowserRouter>
      <RoutesApp/>
    </BrowserRouter>
  );
}

export default App  